<template>
    <div>
      <CCard>
        <CCardBody>
          <div class="row justify-content-between" v-if="isQuestion == false">
            <div class="col-10">
              <div class="row mb-3">
                <label class="m-1 ml-3" for="">Masukan Jumlah Pertanyaan : </label>
                  <input
                    type="text"
                    v-model="jmlPertanyaan"
                    style="max-width: 200px"
                    class="form-control form-control-sm mx-2"
                    placeholder="Ketik disini"
                  />
                  <button @click="generateTable()" class="btn btn-sm btn-success">
                    Tambah
                  </button>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-10">
              <button @click="info" class="btn btn-sm btn-primary" v-if="isQuestion == false">
                  Panduan
              </button>
            </div>
            <div class="col-2">
              <div class="row mb-3">
                <button @click="submit" class="btn btn-sm btn-primary" v-if="isQuestion == false">
                  Submit All Tables
                </button>
                <button @click="update" class="btn btn-sm btn-primary" v-else>
                  Update All Tables
                </button>
              </div>
            </div>
          </div>
          <template>
            <div class="container">
              <div class="row">
                <div class="col-12">
                    <div class="table-container">
                      <div v-for="group in listTemplateGroup" :key="group.name" class="table-wrapper col-md-12 col-sm-12">
                        <span style="color: black;"> Desc: {{ group.description }}</span>
                        <table class="table table-bordered">
                          <caption>
                            Group {{ group.name }}
                          </caption>
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Question</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(row, rowIndex) in group.questions" :key="rowIndex">
                              <td>{{ rowIndex + 1 }}</td>
                              <td>
                                <input v-model="row['text' + group.name + rowIndex]" type="text" class="form-control">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </template>
        </CCardBody>
      </CCard>
    </div>
  </template>
  
  <script>
  import * as data from "../../model/test-bundle";
  import { uploadImage } from "@/utils/fileUpload";
  import FileSaver from "file-saver";
  
  export default {
    data() {
      return {
        user: JSON.parse(localStorage.getItem("user")),
        params: {
          sorttype: "desc",
          sortby: "id",
          row: 100,
          page: 1,
          keyword: "",
        },
        items: null,
        itemsTotal: 0,
        listTemplateGroup: [],
        templateid: null,
        testId: null,
        isQuestion: false,
        jmlPertanyaan: 0,
        row: null
      };
    },
    methods: {
      submit() {
        var loading = this.$loading.show();
        const questions = {};
  
        this.listTemplateGroup.forEach((group) => {
          const groupInput = group.questions.map((row, rowIndex) => row['text' + group.name + rowIndex]);
          questions[group.name] = groupInput;
        });
  
        const dataKirim = {
          templateId : this.templateid,
          groupId: this.testId,
          dataGroup : this.listTemplateGroup,
          dataSubGroup : null,
          dataQuestions : questions,
        };
  
        this.$store
          .dispatch("test_question/addQuestion", dataKirim)
          .then(() => {
            this.$toast.success("Berhasil menambahkan data");
            loading.hide();
            this.$router.push({ path: 'test-group'});
          })
          .catch((e) => {
            loading.hide();
          });
      },
      update() {
        var loading = this.$loading.show();
        const questions = {};
        const questionsByGroup = {};
  
        this.listTemplateGroup.forEach((data) => {
          questionsByGroup[data.name] = [];
        });
        this.items.forEach((item) => {
          this.listTemplateGroup.forEach((data) => {
            if (item.test_template_groups_id == data.id) {
              questionsByGroup[data.name].push(item.id);
            }
          });
        });
        this.listTemplateGroup.forEach((group) => {
          const groupInput = group.questions.map((row, rowIndex) => row['text' + group.name + rowIndex]);
          questions[group.name] = groupInput;
        });
        
        const dataUpdate = {
          templateId : this.templateid,
          groupId: this.testId,
          dataGroup : this.listTemplateGroup,
          dataSubGroup : null,
          dataQuestions : questions,
          dataQuestionsId : questionsByGroup
        };
        
        console.log(dataUpdate);
  
        this.$store
          .dispatch("test_question/updateQuestion", {
              id: this.testId,
              data: dataUpdate,
            })
          .then(() => {
            this.$toast.success("Berhasil menambahkan data");
            loading.hide();
            this.$router.push({ path: 'test-group'});
          })
          .catch((e) => {
            loading.hide();
          });
      },
      getData() {
        var loading = this.$loading.show();
        this.$store
          .dispatch("test_question/getQuestion", this.testId)
          .then((resp) => {
            this.items = resp.data;
            this.itemsTotal = resp.total;
            
            if (this.items !== "kosong") {
              this.isQuestion = true;
              const rows = new Array(parseInt(this.itemsTotal)).fill(null);
  
              this.listTemplateGroup.forEach((group) => {
                const matchingItems = this.items.filter(item => Number(item.test_template_groups_id) === Number(group.id));
  
                if (matchingItems.length > 0) {
                  const numRows = Math.min(this.itemsTotal, matchingItems.length);
                  group.questions = Array.from({ length: numRows }, (_, rowIndex) => {
                    if (rowIndex < numRows) {
                      return { ['text' + group.name + rowIndex]: matchingItems[rowIndex].question };
                    } else {
                      return {};
                    }
                  });
                } else {
                  group.questions = [...rows];
                }
              });
              console.log(this.listTemplateGroup);
              // console.log(JSON.stringify(this.listTemplateGroup, null, 2));
            }
            loading.hide();
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      getDataGroup() {
        var loading = this.$loading.show();
  
        this.$store
          .dispatch("template/getTemplateGroup", this.templateid)
          .then((resp) => {
            // console.log(this.listTemplateGroup);
            this.listTemplateGroup = resp.data.map(group => ({
                id: group.id,
                name: group.name,
                description: group.description,
                questions: [],
            }));
            this.getData();
            loading.hide();

            // setTimeout(() => {
            //   this.getData();
            //   loading.hide();
            // }, 5000);
          })
          .catch((e) => {
            this.$toast.error("gagal mengambil data  \n", e);
            loading.hide();
          });
      },
      generateTable() {
        const rows = new Array(parseInt(this.jmlPertanyaan)).fill({});
  
        this.listTemplateGroup.forEach((group) => {
          group.questions = [...rows];
        });
      },
      info() {
          this.$swal.fire({icon: 'info', html: 'Silahkan masukan jumlah pertanyaan yang akan di buat!'});
      }
    },
    computed: {
  
    },
    mounted() {
      
    },
    created() {
      this.templateid = this.$route.query.templateid;
      this.testId = this.$route.query.testId;
      this.getDataGroup();
      // console.log(templateid);
      // console.log(testId);
    },
  };
  </script>
  
  <style scoped>
  .table-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .table-wrapper {
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    text-align: center;
  }
  
  caption {
    font-weight: bold;
    text-align: center;
    padding: 10px;
    position: relative;
    top: -10px;
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  </style>